<div class="ui grid">
  <div class="one wide column">
  </div>
  <div class="fourteen wide column">
    <div class="ui piled segment" style="z-index: 1">

      <div class="hj-gallery">
        <ngx-slick-carousel class="carousel"
                            #slickModal="slick-carousel"
                            [config]="slideConfig"
                            (init)="slickInit($event)"
                            (breakpoint)="breakpoint($event)"
                            (afterChange)="afterChange($event)"
                            (beforeChange)="beforeChange($event)">
          <div ngxSlickItem *ngFor="let slide of slides" class="slide">
            <div style="{{slide.style}}"><img src="{{ slide.img }}" alt="" width="100%"></div>
          </div>
        </ngx-slick-carousel>
      </div>

      <script type="text/javascript">
        $(document).ready(function(){
          $('.hj-gallery').slick({
            centerMode: true,
            centerPadding: '60px',
            slidesToShow: 3,
            autoplay: true,
            autoplaySpeed: 5000,
            adaptiveHeight: true,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  arrows: false,
                  centerMode: true,
                  centerPadding: '40px',
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 480,
                settings: {
                  arrows: false,
                  centerMode: true,
                  centerPadding: '40px',
                  slidesToShow: 1
                }
              }
            ]
          });
        });
      </script>

    </div>
    <div class="one wide column">
    </div>
  </div>


  <div class="ui grid">
    <div class="one wide column">
    </div>
    <div class="fourteen wide column">
      <div class="ui segment" style="z-index: 1; border: 5px #6283b2 solid;">
        <div class="ui grid">
          <div class="eight wide column">
            <p style="font-size: 16px">Hey there, my name's Aaron or "Helix" as I'm often known in various art circles. I'm a mostly self-taught artist who's been in the field of doing art for a living for over five years now. I'm a dumb nerd that likes playing and streaming games with friends as well as traveling and hiking. I primarily do commission artwork of the cartoon illustration and comic sort and I appreciate any and all support from my lovely customers so that I can keep doing what I enjoy as a living.</p>
          </div>
          <div class="eight wide column">
            <img src="/media/home-assets/me.jfif" class="ui image fluid">
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--
  <div class="ui grid">
    <div class="one wide column">
    </div>
    <div class="fifteen wide column">
      <div class="ui segment" style="z-index: 1">
        <div class="ui grid">
          <div class="four wide column">
            <img src="https://placehold.it/230x230" class="ui image fluid">
          </div>
          <div class="four wide column">
            <img src="https://placehold.it/230x230" class="ui image fluid">
          </div>
          <div class="four wide column">
            <img src="https://placehold.it/230x230" class="ui image fluid">
          </div>
          <div class="four wide column">
            <img src="https://placehold.it/230x230" class="ui image fluid">
          </div>
        </div>
      </div>
    </div>

  </div>
  -->





</div>
