export class CommEmail {
  name = '';
  email = '';
  communication = '';
  platform = 'twitter';
  typeOfCommission = '';
  references = '';
  idea = '';
  numChars = 1;
  iAgree = false;
}
