<particles [style]="style" [width]="width" [height]="height" [params]="params"></particles>
<div class="ui container b-prop">
  <div class="ui grid" style="background: #789ccd; border: 5px #6283b2 solid; margin-bottom: 17px;">
    <div class="ten wide column">
      <a routerLink="/"><img src="/assets/app/img/hj-banner2.png" class="ui image"></a>
    </div>
    <div class="five wide column">
      <div class="ui b-border secondary vertical pointing menu" style="width: 100%; padding-top: 7px; margin-top: 10%; padding-bottom: 7px; text-align: right">
        <a class="item" style="color: rgba(255,255,255,0.8)" routerLink="/">
          Home
        </a>
        <a class="item" style="color: rgba(255,255,255,0.8)" routerLink="/commission/">
          Commissions
        </a>
        <!--
        <a class="item" style="color: rgba(255,255,255,0.8)" href="#">
          Gallery
        </a>
        -->
        <a class="item" style="color: rgba(255,255,255,0.8)" href="#" target="_blank">
          Kofi
        </a>
      </div>
    </div>
  </div>

  <router-outlet></router-outlet>

</div>


<div class="ui bottom fixed fluid one item menu" style="background: none !important; border-top: 0px;">
  <div class="ui container" style="background: #6283b2; border-right: 0px; border-left: none; border-radius: 25px 25px 0px 0px;">
    <div class="vertically fitted item" style="color: white; border-left: none;">
      &copy; 2022 HelixJack
    </div>
  </div>
</div>
