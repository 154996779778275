import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-front',
  templateUrl: './front.component.html',
  styleUrls: ['./front.component.css']
})
export class FrontComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  slides: any = [
    {img: '/media/gallery/2022-1.jpg', style: ''},
    {img: '/media/gallery/2022-2.jpg', style: ''},
    {img: '/media/gallery/2-25t.png', style: 'padding-top: 10%'},
    {img: '/media/gallery/3-25t.png', style: ''},
    {img: '/media/gallery/4-25t.png', style: ''},
    {img: '/media/gallery/5-25t.png', style: ''},
    {img: '/media/gallery/6-25t.png', style: 'padding-top: 15%'},
    //{img: '/media/gallery/17-25t.png', style: 'padding-top: 20%'},
    {img: '/media/gallery/7-25t.png', style: ''},
    {img: '/media/gallery/1-25t.png', style: ''},
    //{img: '/media/gallery/8-25t.png', style: 'padding-top: 20%'},
    {img: '/media/gallery/9-25t.png', style: 'padding-top: 20%'},
    {img: '/media/gallery/10-25t.png', style: ''},
    {img: '/media/gallery/11-25t.png', style: ''},
    {img: '/media/gallery/12-25t.png', style: ''},
    //img: '/media/gallery/13-25t.png', style: 'padding-top: 20%'},
    {img: '/media/gallery/16-25t.png', style: 'padding-top: 20%'}
  ];
  slideConfig: any = {
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]
  };

  addSlide() {
    this.slides.push({img: "http://placehold.it/350x150/777777"})
  }

  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {
    console.log('afterChange');
  }

  beforeChange(e) {
    console.log('beforeChange');
  }

}
