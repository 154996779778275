import { Component, OnInit } from '@angular/core';
import {CommEmail} from '../models/comm-email';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-commission',
  templateUrl: './commission.component.html',
  styleUrls: ['./commission.component.css']
})
export class CommissionComponent implements OnInit {

  commEmail: CommEmail = new CommEmail();
  amts = {
    icon: 80,
    sketch: 120,
    fullColor: 250,
  };
  isSubmitted = false;
  submitting = false;

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit(): void {
  }


  estQ(): string {
    if (this.commEmail.typeOfCommission === 'other') {
      return 'TBD, This will be discussed further at a later date.';
    }
    return '$' + this.amts[this.commEmail.typeOfCommission] * this.commEmail.numChars + '.00';
  }

  doSubmitComm(): void {
    this.submitting = true;
    if (this.commEmail.iAgree){
          this.http.post('/api/sendMail', this.commEmail)
      .subscribe(
        (res) => {
          this.submitting = false;
          this.isSubmitted = true;
        },
        (err) => {
          this.submitting = false;
          alert('An error occured trying to save your form. Check that everything looks correct then try again.');
        }
      );
    }
    this.submitting = false;
  }
}
